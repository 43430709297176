'use strict';

import utils from './modules/utils';
import search from './modules/search';
import card_logic from './modules/card_logic';
import phone_validator from './modules/phone_validator';
import product_style from './modules/product_style';

document.addEventListener('DOMContentLoaded', function () {
    utils();
    search();
    card_logic();
    phone_validator();
    product_style();
    console.log('DEV:ustsl.ru')
});