export default function utils() {
    console.log(123);
    grecaptcha.ready(function () {
        grecaptcha.execute('6LcCWaEhAAAAACs0s3CgqHR1dQPM1agdPKYLEToT', { action: 'form' })
            .then(function (token) {
                var elements = document.querySelectorAll('#id_captcha');
                elements.forEach(element => {
                    element.value = token;
                });

            });
    });
}