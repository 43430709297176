export default function product_style() {

  // Обрезка характеристик
  let symbolCheckResult = false;
  const chars = document.getElementById('product_chars');

  if (chars) {
    const charsMore = document.getElementById('product_chars__more');
    const charsParagraph = chars.querySelectorAll('p');

    let paragraphCounter = 0;
    displaySettings('none');

    if (paragraphCounter > 7) {
      charsMore.style.display = 'unset';
      charsMore.addEventListener('click', function (e) {
        e.preventDefault();
        displaySettings('block')
        charsMore.style.display = 'none';
      });
    } else {
      symbolCheckResult = symbolCheck();
    }

    function displaySettings(arg) {
      charsParagraph.forEach(p => {
        paragraphCounter++;
        if (paragraphCounter > 7) {
          p.style.display = arg;
        }
      });
    }

    function symbolCheck() {
      const charsContent = chars.querySelector('p');
      const charsContentList = (charsContent.innerHTML).split('<br>');
      if ((charsContentList).length > 5) {
        let shortContent = '';
        (charsContentList.slice(0, 5)).forEach(elem => {
          shortContent += `${elem}<br>`;
        })
        chars.innerHTML = `<p>${shortContent}...</p>`;
        charsMore.style.display = 'unset';
        charsMore.addEventListener('click', function (e) {
          e.preventDefault();
          chars.innerHTML = `<p>${charsContent.innerHTML}</p>`;
          charsMore.style.display = 'none';
        });
      }

      return true
    }


    // Работа с описанием 


    const productContent = document.getElementById('product_content'),
      productContentInner = productContent.querySelector('p');

    if ((productContentInner.innerHTML).length > 500) {
      const shortContent = ((productContentInner.innerHTML).split('<br>'))[0];
      productContentInner.innerHTML = shortContent;
    }


    // Меняем стартовую вкладку


    if (paragraphCounter > 0 || symbolCheckResult) {

      const charsTabLink = document.getElementById('charsTabLink'),
        baseTabLink = document.getElementById('baseTabLink'),
        charsTab = document.getElementById('charsTab'),
        baseTab = document.getElementById('baseTab');

      charsTabLink.classList.add('active');
      baseTabLink.classList.remove('active');
      charsTab.classList.add('active');
      charsTab.classList.add('show');
      baseTab.classList.remove('active');
      baseTab.classList.remove('show');

    }

  }



}
