export default function phone_validator() {

  const phoneArea = document.querySelectorAll('#phoneForm');

  function numberMask(e) {
    let input = e.target,
      inputValue = input.value.replace(/\D/g, ""),
      formattedInputValue = '';

    if (!inputValue) {
      input.value = '';
      return input;
    }

    if (["7", "8", "9"].indexOf(inputValue[0]) > -1) {

      if (inputValue[0] == 7) {
        input.value = `+${inputValue}`;
        return input;
      }

      if (inputValue[0] == 9) {
        input.value = `+7${inputValue}`;
        return input;
      } else {
        return input;
      }


    } else {
      input.value = `+${inputValue}`;
      return input;
    }

  }

  phoneArea.forEach(area => {
    area.addEventListener('input', function (e) {
      numberMask(e);
    });
  });

}
