export default function search() {

  const searchField = document.getElementById("searchField");
  searchField.addEventListener('input', function (e) {

    if (searchField.value.length > 2) {
      goSearch(searchField.value);
    } else {
      console.log('запрос слишком короткий')
    }
  });


  function goSearch(query) {

    fetch('/search/ajax/', {
      method: 'POST',
      body: JSON.stringify({ user_search: query }),
      headers: {
        'Content-Type': 'application/json',
        "X-Requested-With": "XMLHttpRequest"
      },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      extractProducts(data['data']);
    }).catch(() => {
      console.log('ошибка');
    });
  }

  function extractProducts(data) {

    const products = JSON.parse(data);
    let templ = '';
    if (products.length < 1) {
      templ += `Ничего не найдено.`;
    } else {
      products.forEach(elem => {
        let elemData = elem['fields'],
          elemSlug = elemData['slug'],
          elemTitle = elemData['title'],
          elemPrice = elemData['price'];

        templ += `
              <div class="row align-items-center position-relative mb-5">          
                  <div class="col position-static">
                      <!-- Text -->
                      <p class="mb-0 font-weight-bold">
                      <a class="stretched-link text-body" href="/product/${elemSlug}">${elemTitle}</a> <br>
                      <span class="text-muted">${elemPrice} руб.</span>
                      </p>
                   </div>
              </div>
              `;
      });
    }
    renderProducts((templ));

  }


  function renderProducts(templ) {
    const searchTop = document.querySelector('#searchTop');

    searchTop.innerHTML = templ;
  }
}

