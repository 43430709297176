export default function card_logic() {

  // Стартовый запуск базового функционала

  contentBasketRender();
  productCardsFunc();
  basketCounterFieldListener();

  // Реализация выкладки корзины

  function productCardsFunc() {
    // Реакция на кнопку добавления товара
    const productCards = document.querySelectorAll('#productCard');

    productCards.forEach(product => {
      const addToCard = product.querySelector('#addToCard');
      const nameProduct = product.querySelector('#nameProduct');
      const priceProduct = product.querySelector('#priceProduct');
      addToCard.addEventListener('click', function (e) {
        e.preventDefault();
        //Создаем пару в формате json
        const nameProductValue = nameProduct.innerHTML.replace(/\s/g, '');
        const priceProductValue = priceProduct.innerHTML.replace(/\s/g, '');
        let element = {};
        element[nameProductValue] = priceProductValue;

        //Проверяем localstorage
        let data = localStorage.getItem('ustsl_ru__shop_basket');
        if (data) {
          //Если есть, то получаем данные и дописываем
          data = JSON.parse(data);
          data[nameProductValue] = priceProductValue;
          localStorage.setItem('ustsl_ru__shop_basket', JSON.stringify(data));
        } else {
          //Если его нет - пишем с нуля
          localStorage.setItem('ustsl_ru__shop_basket', JSON.stringify(element));
        }
        renderCardButton(product);
        contentBasketRender();
      });
    })
  }

  // Рендер кнопки корзины в товаре

  function renderCardButton(product) {
    if (product) {
      const basketButtonField = product.querySelector('#basketButtonField');
      basketButtonField.innerHTML = `
          <a class="text-mutted" data-toggle="modal" href="#modalShoppingCart">            
          Открыть корзину
          </a>`;
    }
  }

  // Рендер контента в корзине

  function contentBasketRender() {

    // Запускать каждый раз при изменении

    const basketList = document.querySelector('#basketProductList');
    const dataForBasket = localStorage.getItem('ustsl_ru__shop_basket');

    if (dataForBasket) {
      const jsonDataForBasket = JSON.parse(dataForBasket);
      let result = '';
      for (let key in jsonDataForBasket) {
        const productElem = `
                  <li class="list-group-item" id="basketItemElem">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <!-- Title -->
                          <p class="font-size-sm font-weight-bold mb-6">
                            <span id="basketProductName">${key}</span>
                            <br>
                            <span class="text-muted" id="basketProductPrice">${jsonDataForBasket[key]} руб/шт</span>
                          </p>      
                          <!--Footer -->
                          <div class="d-flex align-items-center">                      
                            <input 
                            type="number" 
                            class="form-control form-control-sm"
                            max="999"
                            placeholder="Кол-во" 
                            style="max-width: 100px" 
                            id="totalAmount" 
                            value=1>  
                            <!-- Remove -->
                            <a class="font-size-xs text-gray-400 ml-auto" href="#" id="deleteItem">
                              <i class="fe fe-x"></i> Удалить
                            </a>      
                          </div>      
                        </div>
                      </div>
                    </li>         
                  `;

        result += productElem;
      }
      basketList.innerHTML = result;
    }
    deleteBasketItem();
    basketCounterFunc();
    basketCounterFieldListener();
  }

  // Удаление продукта из корзины

  function deleteBasketItem() {
    const basketItemElems = document.querySelectorAll('#basketItemElem');

    basketItemElems.forEach(elem => {
      const deleteItemButton = elem.querySelector('#deleteItem');
      const basketProductName = elem.querySelector('#basketProductName');

      deleteItemButton.addEventListener('click', function (e) {
        e.preventDefault();
        const key = basketProductName.innerHTML;
        const dataForBasket = localStorage.getItem('ustsl_ru__shop_basket');
        const data = JSON.parse(dataForBasket);
        delete data[key];
        localStorage.setItem('ustsl_ru__shop_basket', JSON.stringify(data));
        contentBasketRender();
      });
    })
  }

  // Прослушивание полей кол-ва товаров

  function basketCounterFieldListener() {

    const fields = document.querySelectorAll('#totalAmount');

    fields.forEach(amountInput => {
      const amountInputListener = (e) => {
        e.preventDefault();
        basketCounterFunc();
      }
      amountInput.addEventListener('input', amountInputListener);
    })

  }

  // Счетчик корзины


  function basketCounterFunc() {

    const basketItemElems = document.querySelectorAll('#basketItemElem');
    const basketCounter = document.querySelector('#basketCounter');
    const basketTotal = document.querySelector('#basketTotal');

    let paymentCounter = 0;

    basketItemElems.forEach(elem => {
      const amountInput = elem.querySelector('#totalAmount');
      const productPrice = elem.querySelector('#basketProductPrice').innerHTML;

      if (amountInput.value < 10000) {
        paymentCounter += Number(productPrice.split(' ')[0]) * amountInput.value;
      }
      else {
        paymentCounter += Number(productPrice.split(' ')[0]) * 9999;
      }

    });

    basketCounter.setAttribute('data-cart-items', basketItemElems.length);
    basketTotal.innerHTML = `${paymentCounter.toFixed(1)} руб.`;
  }

  // Отправка формы

  const basketForm = document.querySelector('#basketFinalForm');

  basketForm.onsubmit = async (e) => {
    e.preventDefault();
    const userName = basketForm.elements.name.value;
    const userPhone = basketForm.elements.phone.value;
    const userProducts = localStorage.getItem('ustsl_ru__shop_basket');
    const amountInput = document.querySelectorAll('#totalAmount');


    const jsonUserProducts = JSON.parse(userProducts);
    const keysUserProducts = Object.keys(jsonUserProducts);
    let amountCounter = 0;
    keysUserProducts.forEach(key => {
      jsonUserProducts[key] = `Цена единицы: ${jsonUserProducts[key]}  Кол-во: ${amountInput[amountCounter].value}`
      amountCounter++
    })


    if (!userProducts || userProducts.length == 2) {
      const basketList = document.querySelector('#basketProductList');
      basketList.innerHTML = `<p style="padding: 20px;">Ваша корзина пуста. Для отправки корзины наполните ее товарами.</p>`;
    } else {
      grecaptcha.ready(function () {
        grecaptcha.execute('6LcCWaEhAAAAACs0s3CgqHR1dQPM1agdPKYLEToT', { action: 'submit' }).then(function (token) {
          basketForm.innerHTML =
            `<div style="width:200px; margin-left: auto; margin-right: auto;">  
                          <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', background: 'none', display: 'block'}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                          <g transform="translate(80,50)">
                          <g transform="rotate(0)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="1">
                          <animateTransform attributeName="transform" type="scale" begin="-0.875s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.875s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(71.21320343559643,71.21320343559643)">
                          <g transform="rotate(45)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.875">
                          <animateTransform attributeName="transform" type="scale" begin="-0.75s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.75s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(50,80)">
                          <g transform="rotate(90)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.75">
                          <animateTransform attributeName="transform" type="scale" begin="-0.625s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.625s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(28.786796564403577,71.21320343559643)">
                          <g transform="rotate(135)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.625">
                          <animateTransform attributeName="transform" type="scale" begin="-0.5s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.5s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(20,50.00000000000001)">
                          <g transform="rotate(180)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.5">
                          <animateTransform attributeName="transform" type="scale" begin="-0.375s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.375s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(28.78679656440357,28.786796564403577)">
                          <g transform="rotate(225)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.375">
                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.25s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(49.99999999999999,20)">
                          <g transform="rotate(270)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.25">
                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.125s"></animate>
                          </circle>
                          </g>
                          </g><g transform="translate(71.21320343559643,28.78679656440357)">
                          <g transform="rotate(315)">
                          <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.125">
                          <animateTransform attributeName="transform" type="scale" begin="0s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                          <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="0s"></animate>
                          </circle>
                          </g>
                          </g>
                      </svg>
                  </div>`;

          sendMessage(userName, userPhone, JSON.stringify(jsonUserProducts), token)

        });
      });
    }
  };

  function sendMessage(userName, userPhone, userProducts, token) {

    fetch('/ajax_form/', {
      method: 'POST',
      body: JSON.stringify({ user_name: userName, user_phone: userPhone, user_products: userProducts, token: token }),
      headers: {
        'Content-Type': 'application/json',
        "X-Requested-With": "XMLHttpRequest"
      },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.result == 1) {
        localStorage.removeItem('ustsl_ru__shop_basket');
        location.replace("/thanks/");
      }
      else {
        location.replace("/error");
      }
    }).catch(() => {
      console.log('ошибка');
    });
  }

}

